import React from 'react'
import { Link } from 'gatsby'
import styles from './layout.module.scss'
import { Helmet } from 'react-helmet/es/Helmet'
import 'lazysizes'

class Layout extends React.Component {
  render() {
    const { location, title, children, wide } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div>
        <Helmet>
          <script type="text/javascript">{`window.$crisp=[];window.CRISP_WEBSITE_ID="54e193f9-b49e-4213-866a-f9d706cd2ed2";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}</script>
        </Helmet>

        <header className={styles.header}>
          <Link to="/" className={styles.logo}>
            <img height="30" src="/EchoWaveIO.svg" alt="EchoWave Logo" />
          </Link>
          <input className={styles.menuBtn} type="checkbox" id="menu-btn" />
          <label className={styles.menuIcon} htmlFor="menu-btn">
            <span className={styles.navicon} />
          </label>
          <ul className={styles.menu}>
            <li>
              <Link to="/audio-waveform-video-generator/">Audio Waveform Video</Link>
            </li>
            <li>
              <Link className={styles.footerLink} to="/how-to-cite-a-podcast/">
                {' '}
                Cite a Podcast{' '}
              </Link>
            </li>
            <li>
              <Link to="/pricing/">Pricing</Link>
            </li>
            <li>
              <a href="https://app.echowave.io/login">Login</a>
            </li>
            <li className={styles.register}>
              <a href="https://app.echowave.io/register">Get Started →</a>
            </li>
          </ul>

          <div className={styles.subscribe}>
            <a href="https://app.echowave.io/register">Convert Audio to Video →</a>
          </div>
        </header>

        <main className={wide ? styles.mainContentWide : styles.mainContent}>{children}</main>

        <div className={styles.shareContainer}>
          <div className="addthis_inline_share_toolbox" />
        </div>

        <footer className={styles.footer}>
          <div className={styles.footerLinks}>
            <div className={styles.footerLink}>
              <h5>Podcast Promotion</h5>

              <ul>
                <li>
                  <Link className={styles.footerLink} to="/podcast-to-video/">
                    Podcast To Video
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/podcast-seo/">
                    Podcast SEO Guide
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/podcast-graphic/">
                    Podcast Graphic
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-post-audio-on-facebook/">
                    {' '}
                    How to Post audio on Facebook{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-post-a-podcast-on-instagram/">
                    {' '}
                    How to Post a Podcast on Instagram{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/podcast-promotion/">
                    {' '}
                    Podcast Promotion Guide
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/podcast-teaser-trailer/">
                    {' '}
                    Podcast Trailers
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-make-a-podcast-media-kit/">
                    Podcast Press Kit
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/podcast-topic/">
                    Choose a Podcast Topic
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-post-your-podcast-on-youtube/">
                    {' '}
                    Upload podcast to YouTube{' '}
                  </Link>
                </li>
              </ul>
            </div>

            <div className={styles.footerLink}>
              <Link className={styles.footerLink} to="/guides/">
                <h5>Guides</h5>
              </Link>

              <ul>
                <li>
                  <Link className={styles.footerLink} to="/videocasts/">
                    {' '}
                    Videocasts (Video Podcasts) Guide{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/audio-waveform-video-generator/">
                    {' '}
                    Audio Waveform Video Generator{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-interview-for-a-podcast/">
                    {' '}
                    How to interview for a podcast{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/podcast-questions-list/">
                    {' '}
                    Podcast Questions List{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/remote-podcast-recording/">
                    {' '}
                    Remote Podcasting{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-do-podcasts-make-money/">
                    {' '}
                    How do podcasts make money{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-write-podcast-show-notes/">
                    {' '}
                    How to Write Podcast Show Notes{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-add-sound-to-instagram-pictures/">
                    {' '}
                    How to add sound to instagram pictures{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-make-a-podcast-intro/">
                    {' '}
                    How to make a podcast intro{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-name-your-podcast/">
                    {' '}
                    How to name your podcast{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/how-to-add-subtitles-to-a-video/">
                    {' '}
                    Add Subtitles to a Videos{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/translate-video/">
                    {' '}
                    Translate Video{' '}
                  </Link>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/video-resizer/">
                    {' '}
                    Resize Video{' '}
                  </Link>
                </li>
              </ul>
            </div>

            <div className={styles.footerLink}>
              <h5>Echowave</h5>

              <ul>
                <li>
                  <a className={styles.footerLink} href="/docs/">
                    Documentation
                  </a>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/terms/">
                    Terms of Serice
                  </Link>
                </li>
                <li>
                  <li><Link className={styles.footerLink} to='/privacy/' >Privacy Policy</Link></li>
                </li>
                <li>
                  <Link className={styles.footerLink} to="/pricing/">
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <img height="30" src="/logoWithBackgroundLight.svg" alt="EchoWave Logo" />

          <div style={styles.echowaveCopy}>© Echowave.io 2021</div>
        </footer>
        <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5fab1e462cee2b96" />
      </div>
    )
  }
}

export default Layout
